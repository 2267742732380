import Vue from 'vue'

// axios
import axios from 'axios'
import apiLinks from '../axios.config'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: apiLinks.main,
})

// NH Reports API instance
const axiosReportsIns = axios.create({
  baseURL: apiLinks.reports,
})

// Optional: set up interceptors for each instance
const addAuthInterceptor = axiosInstance => {
  axiosInstance.interceptors.request.use(request => {
    const authToken = localStorage.getItem('adminToken')
    if (authToken) {
      // eslint-disable-next-line no-param-reassign
      request.headers.Token = authToken
    }
    return request
  })
}

addAuthInterceptor(axiosIns)
addAuthInterceptor(axiosReportsIns)

// Attach one of them to Vue prototype if that’s your default
Vue.prototype.$http = axiosIns

// Export all instances so you can import and use them as needed
export { axiosIns, axiosReportsIns }
