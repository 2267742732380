<template>
  <div>
    <!-- Step Timeline -->
    <b-container class="step-timeline mb-3">
      <b-row class="justify-content-center">
        <b-col
          v-for="(step, index) in steps"
          :key="index"
          class="text-center"
          :class="['step', { active: currentStep === index + 1, completed: currentStep > index + 1 }]"
          @click="handleStepClick(index + 1)"
        >
          <div class="circle">
            {{ index + 1 }}
          </div>
          <div class="label">
            {{ step.label }}
          </div>
          <div class="line" />
        </b-col>
      </b-row>
    </b-container>
    <!-- Step Content -->
    <div class="step-content">
      <component
        :is="currentComponent"
        :current-step="currentStep"
      />
    </div>
  </div>
</template>

<script>
import DataUpload from './DataUpload.vue'
import DataReview from './DataReview.vue'
import PdfUpload from './PdfUpload.vue'
import PdfReview from './PdfReview.vue'
import FinalReview from './FinalReview.vue'

export default {
  data() {
    return {
      currentStep: 1, // Default to the first step
      steps: [
        { label: 'Data Upload', component: DataUpload },
        { label: 'Data Review', component: DataReview },
        { label: 'PDF Upload', component: PdfUpload },
        { label: 'PDF Review', component: PdfReview },
        { label: 'Final Review', component: FinalReview },
      ],
    }
  },
  computed: {
    currentComponent() {
      return this.steps[this.currentStep - 1].component
    },
  },
  methods: {
    handleStepClick(stepNumber) {
      this.currentStep = stepNumber // Update the current step
    },
  },
}
</script>

<style scoped>
.step-timeline {
  margin-top: 20px;
}

.step {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #e0e0e0;
  font-weight: bold;
  color: white;
}

.step.active .circle {
  background-color: #6c63ff;
}

.step.completed .circle {
  background-color: #28a745;
}

.label {
  margin-top: 10px;
}

.line {
  position: absolute;
  top: 25px;
  left: 50%; /* Start from the center of the circle */
  width: calc(100% - 20px); /* Stretch to connect to the next step */
  height: 2px;
  background-color: #e0e0e0;
  z-index: -1;
}

.step.active ~ .line,
.step.completed ~ .line {
  background-color: #6c63ff;
}

.step:last-child .line {
  display: none; /* Remove the line after the last step */
}

</style>
