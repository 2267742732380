<template>
  <div v-if="barcodeData !== null">
    <div v-if="!isPdfReviewReady">
      NO REPORTS FOR REVIEW
    </div>
    <div v-else>
      <b-card
        v-if="barcodeData !== null && typeof barcodeData.reUploadedReports !== 'undefined'"
        :title="
          `View Unpublished Reports for ${barcodeData.duBarcode || barcodeData.stBarcode || barcodeData.saBarcode}  `
        "
      >
        <main>
          <b-card
            v-for="(value, propertyName, index) in barcodeData.reUploadedReports"
            :key="index"
            :title="Capitalize(propertyName) + ' Report'"
            class="mx-1"
          >
            <div class="d-flex flex-row align-items-center justify-content-between">
              {{ barcodeData.corBarcode || barcodeData.duBarcode || barcodeData.stBarcode || barcodeData.saBarcode }}_{{ propertyName }}.pdf
              <div
                v-if="typeof barcodeData.reports !== 'undefined'"
                class="d-flex align-items-center justify-content-center"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="handleDownload(propertyName, 'unpublished')"
                >
                  Download
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="ml-1"
                  variant="outline-primary"
                  @click="handleViewPDF(propertyName, 'unpublished')"
                >
                  View
                </b-button>
              </div>
            </div>
            <span
              v-if="valid.val && valid.index === 0"
              class="text-danger font-weight-bolder my-1"
            >{{ valid.message }}</span>
          </b-card>
        </main>
        <!-- Comments Section -->
        <b-form-group>
          <b-form-textarea
            id="comments"
            v-model="comments"
            rows="3"
            placeholder="Enter your comments here..."
          />
        </b-form-group>
        <!-- Publish and Reject Buttons -->
        <div class="d-flex justify-content-between">
          <div>
            <b-button
              variant="primary"
              class="mr-2"
              @click="changePdfStatus(currentStep === 5 ? 'Finalized' : 'Accepted')"
            >
              Accept
            </b-button>
            <b-button
              variant="danger"
              @click="changePdfStatus('Rejected')"
            >
              Reject
            </b-button>
          </div>
          <b-button
            v-if="currentStep === 5"
            variant="success"
            @click="handlePublish"
          >
            <span v-if="loading !== true">Publish</span>
            <b-spinner
              v-else
              variant="white"
            />
          </b-button>
        </div>
        <b-modal
          id="modal-xl"
          v-model="showModal"
          ok-only
          ok-title="Close"
          centered
          size="xl"
          :title="currentTitle"
          @hide="handleHide"
        >
          <b-card-text class="h-100 w-100">
            <div
              v-if="loading"
              class="d-flex w-100 align-items-center justify-content-center my-5"
            >
              <b-spinner label="Loading..." />
            </div>
            <object
              v-else
              :data="pdfSrc"
              style="width: 100%; height: 100%"
              type="application/pdf"
            />
          </b-card-text>
        </b-modal>
      </b-card>
      <b-card
        v-if="barcodeData !== null"
        :title="
          `View ${
            barcodeData.reportsPublished === false && typeof barcodeData.reUploadedReports === 'undefined' ? 'Unpublished' : 'Previously Published'
          } Reports for ${barcodeData.duBarcode || barcodeData.stBarcode ||barcodeData.saBarcode}`
        "
      >
        <main>
          <b-card
            v-for="(value, propertyName, index) in barcodeData.reports"
            :key="index"
            :title="Capitalize(propertyName) + ' Report'"
            class="mx-1"
          >
            <div class="d-flex flex-row align-items-center justify-content-between">
              {{ barcodeData.corBarcode || barcodeData.duBarcode || barcodeData.stBarcode || barcodeData.saBarcode }}_{{ propertyName }}.pdf
              <div
                v-if="typeof barcodeData.reports !== 'undefined'"
                class="d-flex align-items-center justify-content-center"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="handleDownload(propertyName)"
                >
                  Download
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="ml-1"
                  variant="outline-primary"
                  @click="handleViewPDF(propertyName)"
                >
                  View
                </b-button>
              </div>
            </div>
            <span
              v-if="valid.val && valid.index === 0"
              class="text-danger font-weight-bolder my-1"
            >{{ valid.message }}</span>
          </b-card>
        </main>
        <!-- Comments Section -->
        <b-form-group v-if="reportsStatus && barcodeData.reportsPublished !== true">
          <b-form-textarea
            id="comments"
            v-model="comments"
            rows="3"
            placeholder="Enter your comments here..."
          />
        </b-form-group>
        <!-- Publish and Reject Buttons -->
        <div
          v-if="reportsStatus && barcodeData.reportsPublished !== true"
          class="d-flex justify-content-between"
        >
          <div>
            <b-button
              variant="primary"
              class="mr-2"
              @click="changePdfStatus(currentStep === 5 ? 'Finalized' : 'Accepted')"
            >
              Accept
            </b-button>
            <b-button
              variant="danger"
              @click="changePdfStatus('Rejected')"
            >
              Reject
            </b-button>
          </div>
          <b-button
            v-if="currentStep === 5"
            variant="success"
            @click="handlePublish"
          >
            <span v-if="loading !== true">Publish</span>
            <b-spinner
              v-else
              variant="white"
            />
          </b-button>
        </div>
        <b-modal
          id="modal-xl"
          v-model="showModal"
          ok-only
          ok-title="Close"
          centered
          size="xl"
          :title="currentTitle"
          @hide="handleHide"
        >
          <b-card-text class="h-100 w-100">
            <div
              v-if="loading"
              class="d-flex w-100 align-items-center justify-content-center my-5"
            >
              <b-spinner label="Loading..." />
            </div>
            <object
              v-else
              :data="pdfSrc"
              style="width: 100%; height: 100%"
              type="application/pdf"
            />
          </b-card-text>
        </b-modal>
      </b-card>
    </div>
  </div>
  <div v-else>
    Loading...
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import Ripple from 'vue-ripple-directive'
import { axiosReportsIns } from '@/libs/axios'
/* eslint-disable no-return-assign */
export default {
  directives: { Ripple },
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      viewPdf: null,
      id: null,
      comments: '', // Initialize comments as an empty string
      barcodeData: null,
      healthReportFile: null,
      recommendationReportFile: null,
      wellnessReportFile: null,
      summaryReportFile: null,
      valid: {
        val: false,
        index: null,
        message: 'Incorrect filename',
      },
      showModal: false,
      pdfSrc: null,
      loading: null,
      currentTitle: null,
      reports: {
        published: {
        },
        unpublished: {
        },
      },
    }
  },
  computed: {
    // returns whether all the reports are uploaded or not
    reportsStatus() {
      return this.barcodeData.reportsPublished === false ? (typeof this.barcodeData.reports.health !== 'undefined'
        || typeof this.barcodeData.reports.summary !== 'undefined'
        || typeof this.barcodeData.reports.recommendations !== 'undefined'
        || typeof this.barcodeData.reports.wellness !== 'undefined') : typeof this.barcodeData.reUploadedReports !== 'undefined'
    },
    isPdfReviewReady() {
      return this.barcodeData && this.barcodeData.pdfReview && (
        this.barcodeData.pdfReview.status === 'Ready for Review'
      || (this.barcodeData.pdfReview.status !== 'Rejected' && this.currentStep === 5)
      )
    },

  },
  beforeMount() {
    this.id = this.$route.params.id
    this.$http
      .get(`/barcodes/${this.id}`)
      .then(({ data }) => {
        this.barcodeData = data
      })
      .catch(() => {
        this.barcodeData = {} // Initialize barcodeData to an empty object if the API call fails
        this.handleError()
      }).finally(this.closeLoading)
  },
  methods: {
    Capitalize(str) {
      return str.trim().replace(/^\w/, c => c.toUpperCase())
    },
    getBlob(link) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'GET',
          url: link,
          responseType: 'blob',
        }).then(resp => {
          resolve(window.URL.createObjectURL(resp.data))
        }).catch(err => {
          reject(err)
          this.handleError(err)
        })
          .finally(() => {
            this.loading = false
          })
      })
    },
    async handleDownload(reportType, type = 'published') {
      let url
      if (typeof this.reports[type][reportType] !== 'undefined') {
        url = this.reports[type][reportType]
      } else {
        this.reports[type][reportType] = await this.getBlob(type === 'published' ? this.barcodeData.reports[reportType] : this.barcodeData.reUploadedReports[reportType])
        url = this.reports[type][reportType]
      }
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${this.barcodeData.corBarcode || this.barcodeData.duBarcode || this.barcodeData.saBarcode || this.barcodeData.stBarcode} _${reportType}.pdf`) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    handlePublish() {
      this.loading = true
      if (this.reportsStatus) {
      /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
        this.$http.post(`/barcodes/${this.id}/publish`).then(() => {
          this.$bvToast.toast(`Reports published successfully for ${this.barcodeData.duBarcode || this.barcodeData.stBarcode || this.barcodeData.saBarcode}`, {
            title: 'Successful',
            variant: 'success',
            solid: true,
          })
        }).catch(this.handleError).finally(() => {
          this.loading = false
        })
      } else {
        this.$bvToast.toast('Reports missing', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    handleHide() {
      this.currentTitle = null
      this.pdfSrc = null
    },
    async handleViewPDF(reportType, type = 'published') {
      this.loading = true
      if (typeof this.reports[type][reportType] !== 'undefined') {
        window.open(this.reports[type][reportType], '_blank')
        this.loading = false
      } else {
        this.reports[type][reportType] = await this.getBlob(type === 'published' ? this.barcodeData.reports[reportType] : this.barcodeData.reUploadedReports[reportType])
        window.open(this.reports[type][reportType], '_blank')
      }
      // fetch(this.barcodeData.reports[reportType])
      //   .then(resp => resp.blob())
      //   .then(blob => {
      //     this.pdfSrc = window.URL.createObjectURL(blob)
      //   })
      //   .catch(this.handleError)
      //   .finally(() => {
      //     this.loading = false
      //   })
    },
    async changePdfStatus(status) {
      try {
        await axiosReportsIns.post('v1/admin/pdf-review', {
          barcode: this.barcodeData.duBarcode || this.barcodeData.stBarcode || this.barcodeData.saBarcode,
          status,
          remarks: this.comments,
        })

        this.$bvToast.toast(`Data ${status} successfully.`, {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        this.$bvToast.toast('Error. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr 1fr;
}
</style>
<style lang="scss">

.modal .modal-content {
  height: 90vh;
}
</style>
