<template>
  <div
    v-if="!isDataReviewReady"
  >
    NO DATA FOR REVIEW
  </div>
  <b-card v-else>
    <template #header>
      <div style="display: contents;">
        <h4>View Data for {{ barcodeData.duBarcode || barcodeData.stBarcode || barcodeData.saBarcode }}</h4>
        <b-dropdown
          id="dropdown-1"
          text="Select Data Type"
          variant="outline-primary"
          class="scrollable-dropdown"
          drop-left
        >
          <b-dropdown-item
            v-for="item in dropdownItems"
            :key="item.type"
            @click="reviewJson(item.type)"
          >
            {{ item.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
    <!-- Comments Section -->
    <b-form-group>
      <b-form-textarea
        id="comments"
        v-model="comments"
        rows="3"
        placeholder="Enter your comments here..."
      />
    </b-form-group>

    <!-- Publish and Reject Buttons  -->
    <div class="d-flex justify-content-between">
      <div>
        <b-button
          class="mr-2"
          variant="primary"
          @click="changeDataStatus(currentStep === 5 ? 'Finalized' : 'Accepted')"
        >
          Accept
        </b-button>
        <b-button
          variant="danger"
          @click="changeDataStatus('Rejected')"
        >
          Reject
        </b-button>
      </div>
      <b-button
        v-if="currentStep === 5"
        variant="success"
        @click="publishData"
      >
        Publish
      </b-button>
    </div>
    <!-- JSON Data Modal  -->
    <b-modal
      id="json-data-modal"
      v-model="isModalVisible"
      title="JSON Data"
      size="lg"
      hide-footer
      body-class="modal-body-scroll"
    >
      <json-viewer :value="jsonData" />
    </b-modal>
  </b-card>
</template>

<script>
import { BButton, BFormGroup, BFormTextarea } from 'bootstrap-vue'
import JsonViewer from 'vue-json-viewer'
import { axiosReportsIns } from '@/libs/axios'

export default {
  components: {
    BButton,
    BFormGroup,
    BFormTextarea,
    JsonViewer,
  },
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      barcodeData: null,
      jsonData: null, // Initialize as null for storing fetched JSON data
      comments: '', // Initialize comments as an empty string
      isModalVisible: false, // Control the visibility of the modal
      dropdownItems: [
        { type: 'base-data', label: 'Base Data' },
        { type: 'biological-age-score', label: 'Biological Age Score' },
        { type: 'comorbidities', label: 'Comorbidities' },
        { type: 'diet-chart', label: 'Diet Chart' },
        { type: 'mb-wellness-risk', label: 'MB Wellness Risk' },
        { type: 'mb-condition-risk', label: 'MB Condition Risk' },
        { type: 'mb-condition', label: 'MB Condition' },
        { type: 'mb-wellness', label: 'MB Wellness' },
        { type: 'host-rank', label: 'Host rank' },
        { type: 'host-genotype', label: 'Host genotype' },
        { type: 'inference', label: 'Inference' },
        { type: 'health-score', label: 'Health score' },
        { type: 'lifestyle-comorbidities-score', label: 'Lifestyle Comorbidities Score' },
        { type: 'lifestyle-score', label: 'Lifestyle Score' },
        { type: 'ear-file', label: 'EAR file' },
        { type: 'final-nutrition', label: 'Final Nutrition' },
        { type: 'recipes', label: 'Recipes' },
        { type: 'summary', label: 'Summary' },
        { type: 'lifestyle-reco', label: 'Lifestyle Recommendation' },
        { type: 'exercise-reco', label: 'Exercise Recommendation' },
      ],
    }
  },
  computed: {
    isDataReviewReady() {
      return this.barcodeData && this.barcodeData.dataReview && (
        this.barcodeData.dataReview.status === 'Ready for Review'
      || (this.barcodeData.dataReview.status !== 'Rejected' && this.currentStep === 5)
      )
    },
  },
  beforeMount() {
    this.showLoading()
    this.id = this.$route.params.id
    this.$http
      .get(`/barcodes/${this.id}`)
      .then(({ data }) => {
        this.barcodeData = data
      })
      .catch(this.handleError).finally(this.closeLoading)
  },
  methods: {
    // Fetch JSON data from an API endpoint
    async fetchData(endpoint, type) {
      try {
        const response = await axiosReportsIns.get(endpoint, {
          params: { barcode: this.barcodeData.duBarcode || this.barcodeData.stBarcode || this.barcodeData.saBarcode }, // Replace with your actual API endpoint and parameters
        })

        switch (type) {
          case 'base-data':
            this.jsonData = response.data || 'Not found'
            break
          case 'biological-age-score':
            this.jsonData = response.data || 'Not found'
            break
          case 'comorbidities':
            this.jsonData = response.data.comorbidities || 'Not found'
            break
          case 'diet-chart':
            this.jsonData = response.data.diet_chart || 'Not found'
            break
          case 'mb-wellness-risk':
            this.jsonData = response.data || 'Not found'
            break
          case 'mb-condition-risk':
            this.jsonData = response.data || 'Not found'
            break
          case 'mb-condition':
            this.jsonData = response.data || 'Not found'
            break
          case 'mb-wellness':
            this.jsonData = response.data || 'Not found'
            break
          case 'host-rank':
            this.jsonData = response.data || 'Not found'
            break
          case 'host-genotype':
            this.jsonData = response.data || 'Not found'
            break
          case 'inference':
            this.jsonData = response.data || 'Not found'
            break
          case 'health-score':
            this.jsonData = response.data || 'Not found'
            break
          case 'lifestyle-comorbidities-score':
            this.jsonData = response.data || 'Not found'
            break
          case 'lifestyle-score':
            this.jsonData = response.data || 'Not found'
            break
          case 'ear-file':
            this.jsonData = response.data || 'Not found'
            break
          case 'final-nutrition':
            this.jsonData = response.data || 'Not found'
            break
          case 'recipes':
            this.jsonData = response.data || 'Not found'
            break
          case 'summary':
            this.jsonData = response.data || 'Not found'
            break
          case 'lifestyle-reco':
            this.jsonData = response.data || 'Not found'
            break
          case 'exercise-reco':
            this.jsonData = response.data || 'Not found'
            break

          default:
            this.jsonData = 'Not found'
        }
      } catch (error) {
        this.$bvToast.toast('Error fetching JSON data. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },

    // Review button clicked: fetch and display JSON data
    async reviewJson(type) {
      let endpoint = ''
      switch (type) {
        case 'base-data':
          endpoint = '/v1/admin/base-data'
          break
        case 'biological-age-score':
          endpoint = '/v1/admin/biological-age-score'
          break
        case 'comorbidities':
          endpoint = '/v1/admin/comorbidities'
          break
        case 'diet-chart':
          endpoint = '/v1/admin/diet-chart'
          break
        case 'mb-wellness-risk':
          endpoint = '/v1/admin/mb-wellness-risk'
          break
        case 'mb-condition-risk':
          endpoint = '/v1/admin/mb-condition-risk'
          break
        case 'mb-condition':
          endpoint = '/v1/admin/mb-condition'
          break
        case 'mb-wellness':
          endpoint = '/v1/admin/mb-wellness'
          break
        case 'host-rank':
          endpoint = '/v1/admin/host-rank'
          break
        case 'host-genotype':
          endpoint = '/v1/admin/host-genotype'
          break
        case 'inference':
          endpoint = '/v1/admin/inference'
          break
        case 'health-score':
          endpoint = '/v1/admin/health-score'
          break
        case 'lifestyle-comorbidities-score':
          endpoint = '/v1/admin/lifestyle-comorbidities-score'
          break
        case 'lifestyle-score':
          endpoint = '/v1/admin/lifestyle-score'
          break
        case 'ear-file':
          endpoint = '/v1/admin/ear-file'
          break
        case 'final-nutrition':
          endpoint = '/v1/admin/final-nutrition'
          break
        case 'recipes':
          endpoint = '/v1/admin/recipes'
          break
        case 'summary':
          endpoint = '/v1/admin/summary'
          break
        case 'lifestyle-reco':
          endpoint = '/v1/admin/lifestyle-reco'
          break
        case 'exercise-reco':
          endpoint = '/v1/admin/exercise-reco'
          break
        default:
          endpoint = 'v1/admin/base-data'
          return
      }

      await this.fetchData(endpoint, type) // Wait for the JSON data to be fetched first

      // Ensure data is fetched
      if (this.jsonData) {
        const blob = new Blob([JSON.stringify(this.jsonData, null, 2)], { type: 'application/json' })
        const url = URL.createObjectURL(blob)
        window.open(url, '_blank')
      } else {
        this.$bvToast.toast('No data fetched yet. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    // Publish data with comments
    async publishData() {
      try {
        await axiosReportsIns.post('v1/admin/data-publish', {
          barcode: this.barcodeData.duBarcode || this.barcodeData.stBarcode || this.barcodeData.saBarcode,
          remarks: this.comments,
        })

        this.$bvToast.toast('Data published successfully.', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        this.$bvToast.toast('Error publishing data. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },

    async changeDataStatus(status) {
      try {
        await axiosReportsIns.post('v1/admin/data-review', {
          barcode: this.barcodeData.duBarcode || this.barcodeData.stBarcode || this.barcodeData.saBarcode,
          status,
          remarks: this.comments,
        })

        this.$bvToast.toast(`Data ${status} successfully.`, {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        this.$bvToast.toast('Error changing data status. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
  },
}
</script>

<style>
.modal-body-scroll {
  max-height: 90vh;
  overflow-y: auto;
}
.scrollable-dropdown .dropdown-menu {
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
}
</style>
