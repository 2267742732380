<template>
  <div>
    <b-card
      v-if="barcodeData != null"
      :title="`Upload JSON file for ${barcodeData.duBarcode || barcodeData.stBarcode || barcodeData.saBarcode}`"
    >
      <h5
        v-if="barcodeData.dataReview.remarks"
        class="mb-2"
      >
        Comments : <span class="text-primary">{{ barcodeData.dataReview.remarks }}</span>
      </h5>
      <b-form-file
        v-model="selectedFile"
        accept=".json"
        @change="onFileChange()"
      />
      <!-- <b-progress
        v-if="progress !== null"
        :value="progress"
        max="100"
        class="mt-3"
      /> -->
      <!-- <div v-if="error">
        <b-card
          title="Validation Errors"
          class="mt-3"
        >
          <ul>
            <li
              v-for="(err, index) in error"
              :key="index"
            >
              <strong>{{ err.instancePath }}</strong>: {{ err.message }}
              <br>
              <em>(Schema: {{ err.schemaName }}, Expected: {{ err.params.type }})</em>
            </li>
          </ul>
        </b-card>
      </div> -->
      <!-- <div v-if="valid">
        File is valid!
      </div> -->
      <div class="d-flex justify-content-end">
        <b-button
          variant="outline-primary"
          class="mr-2 mt-2"
          @click="saveJson"
        >
          Save
        </b-button>
        <b-button
          variant="success"
          class="mt-2"
          :disabled="barcodeData.dataReview.status === 'Pending'"
          @click="jsonReady"
        >
          Ready for Review
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-webpack-loader-syntax, import/extensions
// import Worker from 'worker-loader!@/views/worker.js'
import { axiosReportsIns } from '@/libs/axios'

export default {
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      valid: null,
      selectedFile: null, // Data property to hold the selected file
      barcodeData: null,
    }
  },
  beforeMount() {
    this.showLoading()
    this.id = this.$route.params.id
    this.$http
      .get(`/barcodes/${this.id}`)
      .then(({ data }) => {
        this.barcodeData = data
      })
      .catch(() => {
        this.barcodeData = {} // Initialize barcodeData to an empty object if the API call fails
        this.handleError()
      }).finally(this.closeLoading)
  },
  methods: {
  //   handleFileChange(event) {
  //     const file = event.target.files[0]
  //     const reader = new FileReader()
  //     reader.onload = () => {
  //       const fileData = JSON.parse(reader.result)

    //       const worker = new Worker()
    //       worker.postMessage({ data: fileData })
    //       worker.onmessage = e => {
    //         if (e.data.error) {
    //           // Check if errors are in array format and set error state accordingly
    //           if (Array.isArray(e.data.error)) {
    //             this.error = e.data.error.map(err => ({
    //               instancePath: err.instancePath || 'Unknown Path',
    //               message: err.message || 'Unknown Error',
    //               schemaName: err.schemaName || 'Unknown Schema',
    //               params: err.params || {},
    //             }))
    //           } else {
    //             this.error = [{ message: e.data.error }]
    //           }
    //           this.valid = false
    //         } else if (e.data.valid) {
    //           this.error = null
    //           this.valid = true
    //         }
    //       }
    //     }
    //     reader.readAsText(file)
    //   },
    onFileChange(event) {
      [this.selectedFile] = event.target.files // Use array destructuring to get the first file
    },
    async saveJson() {
      if (!this.selectedFile) {
        this.$bvToast.toast('Please select a file first.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }
      const formData = new FormData()
      formData.append('file', this.selectedFile)

      try {
        await axiosReportsIns.post('/v1/admin/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.$bvToast.toast('File uploaded successfully.', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        this.$bvToast.toast('Error uploading file. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    async jsonReady() {
      try {
        await axiosReportsIns.post('/v1/admin/data-ready-for-review', {
          barcode: this.barcodeData.duBarcode || this.barcodeData.stBarcode || this.barcodeData.saBarcode,
        })

        this.$bvToast.toast('Request sent successfully.', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        this.$bvToast.toast('Error sending request. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
  },

}
</script>
