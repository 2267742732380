<template>
  <div>
    <b-card
      v-if="barcodeData !== null"
      :title="
        `Upload Report for ${barcodeData.duBarcode || barcodeData.stBarcode || barcodeData.saBarcode} `
      "
    >
      <h5
        v-if="barcodeData.pdfReview.remarks"
        class="mb-2"
      >
        Comments : <span class="text-primary">{{ barcodeData.pdfReview.remarks }}</span>
      </h5>
      <main>
        <b-card
          class="mx-1"
        >
          <b-card-title>
            Health Report
            <b-badge
              v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.health !== 'undefined'"
              pill
              variant="light-primary"
              class="ml-2"
            >Uploaded</b-badge>
          </b-card-title>
          <b-input-group>
            <b-form-file
              v-model="healthReportFile"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @input="(event) => inputValidation('health', event)"
            />
            <b-input-group-append>
              <b-button
                v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.health !== 'undefined'"
                variant="outline-primary"
                @click="handleViewPDF('health')"
              >
                View
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-text id="password-help-block">
            File name format: NH-XXXXXXX-DU/SA/ST_health.pdf
          </b-form-text>
          <span
            v-if="valid.val && valid.index === 0"
            class="text-danger font-weight-bolder my-1"
          >{{ valid.message }}</span>
        </b-card>
        <b-card
          class="mx-1"
        >
          <b-card-title>
            Recommendations Report             <b-badge
              v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.recommendations !== 'undefined'"
              pill
              variant="light-primary"
              class="ml-2"
            >Uploaded</b-badge>
          </b-card-title>
          <b-input-group>
            <b-form-file
              v-model="recommendationReportFile"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @input="(event) => inputValidation('recomm', event)"
            />
            <b-input-group-append>
              <b-button
                v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.recommendations !== 'undefined'"
                variant="outline-primary"
                @click="handleViewPDF('recommendations')"
              >
                View
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-text id="password-help-block">
            File name format: NH-XXXXXXX-DU/SA/ST_recommendations.pdf
          </b-form-text>
          <span
            v-if="valid.val && valid.index === 1"
            class="text-danger font-weight-bolder my-1"
          >{{ valid.message }}</span>
        </b-card>
        <b-card
          class="mx-1"
        >
          <b-card-title>
            Wellness Report             <b-badge
              v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.wellness !== 'undefined'"
              pill
              variant="light-primary"
              class="ml-2"
            >Uploaded</b-badge>
          </b-card-title>
          <b-input-group>
            <b-form-file
              v-model="wellnessReportFile"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @input="(event) => inputValidation('wellness', event)"
            />
            <b-input-group-append>
              <b-button
                v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.wellness !== 'undefined'"
                variant="outline-primary"
                @click="handleViewPDF('wellness')"
              >
                View
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-text id="password-help-block">
            File name format: NH-XXXXXXX-DU/SA/ST_wellness.pdf
          </b-form-text>
          <span
            v-if="valid.val && valid.index === 2"
            class="text-danger font-weight-bolder my-1"
          >{{ valid.message }}</span>
        </b-card>
        <b-card
          class="mx-1"
        >
          <b-card-title>
            Summary Report             <b-badge
              v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.summary !== 'undefined'"
              pill
              variant="light-primary"
              class="ml-2"
            >Uploaded</b-badge>
          </b-card-title>
          <b-input-group>
            <b-form-file
              v-model="summaryReportFile"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @input="(event) => inputValidation('summary', event)"
            />
            <b-input-group-append>
              <b-button
                v-if="typeof barcodeData.reports !== 'undefined' && typeof barcodeData.reports.summary !== 'undefined'"
                variant="outline-primary"
                @click="handleViewPDF('summary')"
              >
                View
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-form-text id="password-help-block">
            File name format: NH-XXXXXXX-DU/SA/ST_summary.pdf
          </b-form-text>
          <span
            v-if="valid.val && valid.index === 3"
            class="text-danger font-weight-bolder my-1"
          >{{ valid.message }}</span>
        </b-card>
      </main>
      <div class="d-flex justify-content-end w-100">
        <b-button
          variant="outline-primary"
          class="mr-2"
          @click="submit"
        >
          Save
        </b-button>
        <b-button
          variant="success"
          :disabled="barcodeData.pdfReview.status === 'Pending'"
          @click="pdfReady"
        >
          Ready for Review
        </b-button>
      </div>
    </b-card>
    <b-modal
      id="modal-xl"
      ref="viewPdfRef"
      ok-only
      ok-title="Close"
      centered
      size="xl"
      :title="currentTitle"
      @hide="handleHide"
    >
      <b-card-text class="h-100 w-100">
        <div
          v-if="loading"
          class="d-flex w-100 align-items-center justify-content-center my-5"
        >
          <b-spinner label="Loading..." />
        </div>
        <object
          v-else
          :data="pdfSrc"
          style="width: 100%; height: 100%"
          type="application/pdf"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { axiosReportsIns } from '@/libs/axios'

export default {
  // components: {
  //   reportDataSchema,
  // },
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // to keep track of uploading status of files
      currentTitle: null,
      pdfSrc: null,
      loading: 0,
      id: null,
      barcodeData: null,
      healthReportFile: null,
      recommendationReportFile: null,
      wellnessReportFile: null,
      summaryReportFile: null,
      valid: {
        val: false,
        index: null,
        message: 'Incorrect filename',
      },
      reports: {
      },
    }
  },
  beforeMount() {
    this.showLoading()
    this.id = this.$route.params.id
    this.$http
      .get(`/barcodes/${this.id}`)
      .then(({ data }) => (this.barcodeData = data))
      .catch(this.handleError).finally(this.closeLoading)
  },
  methods: {
    Capitalize(str) {
      return str.trim().replace(/^\w/, c => c.toUpperCase())
    },
    getBlob(link) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'GET',
          url: link,
          responseType: 'blob',
        }).then(resp => {
          resolve(window.URL.createObjectURL(resp.data))
        }).catch(err => {
          reject(err)
          this.handleError(err)
        })
          .finally(() => {
            this.loading = false
          })
      })
    },
    handleHide() {
      this.currentTitle = null
      this.pdfSrc = null
    },
    async handleViewPDF(reportType) {
      this.loading = true
      this.currentTitle = `${this.Capitalize(reportType)} Report`
      if (typeof this.reports[reportType] !== 'undefined') {
        window.open(this.reports[reportType], '_blank')
        this.loading = null
      } else {
        this.reports[reportType] = await this.getBlob(this.barcodeData.reports[reportType])
        window.open(this.reports[reportType], '_blank')
      }
      // fetch(this.barcodeData.reports[reportType])
      //   .then(resp => resp.blob())
      //   .then(blob => {
      //     this.pdfSrc = window.URL.createObjectURL(blob)
      //   })
      //   .catch(this.handleError)
      //   .finally(() => {
      //     this.loading = false
      //   })
    },
    inputValidation(type, file) {
      this.valid = {
        val: false,
        index: null,
        message: 'Incorrect filename',
      }
      const { name } = file
      // eslint-disable-next-line default-case
      switch (type) {
        case 'health':
          if (name !== `${this.barcodeData.duBarcode}_health.pdf` && name !== `${this.barcodeData.stBarcode}_health.pdf` && name !== `${this.barcodeData.saBarcode}_health.pdf`) {
            this.valid = {
              val: true,
              index: 0,
              message: 'Incorrect filename',
            }
          }
          break
        case 'recomm':
          if (name !== `${this.barcodeData.duBarcode}_recommendations.pdf` && name !== `${this.barcodeData.stBarcode}_recommendations.pdf` && name !== `${this.barcodeData.saBarcode}_recommendations.pdf`) {
            this.valid = {
              val: true,
              index: 1,
              message: 'Incorrect filename',
            }
          }
          break
        case 'wellness':
          if (name !== `${this.barcodeData.duBarcode}_wellness.pdf` && name !== `${this.barcodeData.stBarcode}_wellness.pdf` && name !== `${this.barcodeData.saBarcode}_wellness.pdf`) {
            this.valid = {
              val: true,
              index: 2,
              message: 'Incorrect filename',
            }
          }
          break
        case 'summary':
          if (name !== `${this.barcodeData.duBarcode}_summary.pdf` && name !== `${this.barcodeData.stBarcode}_summary.pdf` && name !== `${this.barcodeData.saBarcode}_summary.pdf`) {
            this.valid = {
              val: true,
              index: 3,
              message: 'Incorrect filename',
            }
          }
          break
      }
    },
    async handleUpload(file) {
      const filename = file.name
      try {
        const responseURL = await this.$http
          .post('/barcodes/upload', {
            reportFileName: filename,
          })
        const formData = new FormData()
        formData.append('file', file)
        await this.$http
          .put(responseURL.data, formData, { headers: { 'Content-Type': 'application/pdf' } })
      } catch (err) {
        this.handleError(err)
      }
    },
    async submit() {
      if (this.healthReportFile === null && this.wellnessReportFile === null && this.recommendationReportFile === null && this.summaryReportFile === null) {
        this.$bvToast.toast('Please upload reports', {
          title: 'No reports found',
          variant: 'primary',
          solid: true,
        })
      } else {
        this.showLoading()
        if (this.valid.val) {
          this.closeLoading()
          return
        }
        const filenames = []
        if (this.healthReportFile) {
          filenames.push(this.healthReportFile.name)
          await this.handleUpload(this.healthReportFile)
        }
        if (this.recommendationReportFile) {
          filenames.push(this.recommendationReportFile.name)
          await this.handleUpload(this.recommendationReportFile)
        }
        if (this.wellnessReportFile) {
          filenames.push(this.wellnessReportFile.name)
          await this.handleUpload(this.wellnessReportFile)
        }
        if (this.summaryReportFile) {
          filenames.push(this.summaryReportFile.name)
          await this.handleUpload(this.summaryReportFile)
        }

        await this.$http
          .post('/barcodes/upload/success', { reportFileNames: filenames })
        this.closeLoading()
      }
    },
    async pdfReady() {
      try {
        await axiosReportsIns.post('/v1/admin/pdf-ready-for-review', {
          barcode: this.barcodeData.duBarcode || this.barcodeData.stBarcode || this.barcodeData.saBarcode,
        })

        this.$bvToast.toast('Request sent successfully.', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        this.$bvToast.toast('Error sending request. Please try again.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },

  },
}

</script>

<style lang="scss" scoped>
main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr 1fr;
}
</style>
<style lang="scss">

.modal .modal-content {
  height: 90vh;
}
</style>
