/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
// This is a store for authentication module

import { axiosIns } from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    user: {
      id: null,
      name: null,
      email: null,
      phoneNumber: null,
    },
    token: null,
  },
  getters: {
    GET_USER: state => state.user,
    GET_TOKEN: state => state.token,
  },
  mutations: {
    // -------------------
    // sets user data after fetch
    // ** incomplete **
    //--------------------
    SET_USER: (state, data) => {
      state.user = data
      localStorage.setItem('adminData', JSON.stringify(data))
    },
    // -------------------
    // set adminToken after the user is logged in
    //--------------------
    SET_TOKEN: (state, payload) => {
      if (typeof payload.token !== 'undefined') {
        state.token = payload.token
      }
    },
    // -------------------
    // Logout user
    //--------------------
    LOGOUT_USER: ({ commit }) => new Promise((resolve, reject) => {
      axiosIns
        .delete('/user/logout')
        .then(() => {
          commit('DELETE_USER')
          localStorage.clear()
          resolve()
        })
        .catch(reject)
    }),
  },
  actions: {
    // -------------------
    // used to get user after login
    // potentially obsolete
    //--------------------
    FETCH_USER: ({ commit }, id) => {
      axiosIns.get(`/user/${id}`).then(({ data }) => {
        commit('SET_USER', data)
      })
    },
    LOGOUT_USER: ({ commit }) => new Promise((resolve, reject) => {
      axiosIns
        .delete('/user/logout')
        .then(() => {
          commit('LOGOUT_USER')
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
